<template>
	<v-card>
		<v-container>
			<v-row>
				<s-toolbar
					color="#8E8F91"
					dark
					close
					@close="closeViewJoinPalletChamber()"
					label="Unir Pallets Camara"
					width="auto"
				>
				</s-toolbar>
			</v-row>
			<v-row justify="center">
				<v-col cols="10" >
					<v-data-table
						:headers="headers"
						:items="pallets"
						dense
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.delete="{ item }">
							<v-btn
								outlined
								color="error"
								x-small
								@click="deletePallet(item)"
							>
								ELIMINAR
							</v-btn>
						</template>

						<template v-slot:item.aggregate="{ item }">
							<v-checkbox
								v-model="selectedLlpID"
								:value="item.LlpID"
								@click="addPallet(item)"
							>
							</v-checkbox>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="2" align="end">
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								color="info"
								fab
								x-large
								dark
								@click="createPallet()"
							>
								<span class="white--text text-h4">{{totalJabas}}</span>
							</v-btn>
						</template>
						<span>Unir Pallets</span>
					</v-tooltip>
					
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import _sMatureBalancePalletService from "@/services/FrozenProduction/MatureBalancePalletService";

	export default {
		props: {
			recharge: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				Destiny: 2,
				selectedLlpID: [],
				selectedPallets: [],
				totalJabas: 0,
				pallets: [],
				headers: [
					/* {text: "Acción", value: "delete"}, */
					{text: "Unir", value: "aggregate"},
					{text: "ID", value: "LlpID"},
					{text: "N° Jabas", value: "LlpQuantityJaba"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
				],
			}
		},
		
		watch: {
			recharge() {
				this.Initialize();
			}
		},

		methods: {
			Initialize() 
			{
				_sMatureBalancePalletService
				.listpalletpartition(this.Destiny, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.pallets = resp.data;
						
					}
				})
			},

			addPallet(pallet)
			{
				this.selectedPallets = [];
				this.totalJabas = 0;
				this.pallets.forEach(element => {
					if(this.selectedLlpID.includes(element.LlpID)){
						this.selectedPallets.push(element);
						this.totalJabas = this.totalJabas + element.LlpQuantityJaba;
					}
				});
			},

			closeViewJoinPalletChamber()
			{
				this.$emit("closeViewJoinPalletChamber");
			},

			createPallet()
			{
				if(this.selectedPallets.length == 0){
					this.$fun.alert("No hay pallets seleccionados", "info");
					return;
				}
				this.$fun.alert("¿Seguro de unir pallets?", "question")
				.then(r => {
					if(r.value){
						let objSend = {
							LlpIDString: this.selectedLlpID.join(', '),
							UsrCreateID: this.$fun.getUserID(),
							Destiny: this.Destiny
						}
						console.log('objSend ', objSend);
						_sMatureBalancePalletService
						.joinpallets(objSend, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Pallet creado correctamente", "success");
								this.Initialize();
							}
						})
					}
				})
			}
			
		},

		created () {
			console.log('actualziar siempre');
			this.Initialize();
		},
	}
</script>
