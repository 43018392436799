<template>
	<v-card>
		<!-- <v-container> -->
			<v-row style="margin: auto">
				<s-toolbar
					color="#8E8F91"
					dark
					close
					@close="closeViewJoinPalletTunnel()"
					label="Unir Pallets Túnel"
					width="auto"
				>
				</s-toolbar>
			</v-row>
			<v-row style="margin: auto" justify="center">
				<v-col
				 align="center"
				 cols="6"
				 lg="8"
				 md="8">
					<s-scanner-qr
						:config="configScann"
						return-object
						:autofocus="false"
						@onValue="onValue($event)"
						:readonly="false">
					</s-scanner-qr>

					<!-- agregamos el tipo de parihuela -->
					<v-row justify="center">
						<v-col cols="6">
							<s-select-definition
								label="Tipo parihuela"
								v-model="TypePallet"
								:def="1363">
							</s-select-definition>
						</v-col>
					</v-row>

				</v-col>
			</v-row>
			<v-row style="margin: auto" justify="center">
				<v-col cols="10" >
					<v-data-table
						:headers="headers"
						:items="pallets"
						dense
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.delete="{ item }">
							<v-btn
								outlined
								color="error"
								x-small
								@click="deletePallet(item)"
							>
								ELIMINAR
							</v-btn>
						</template>

						<!-- <template v-slot:item.aggregate="{ item }">
							<v-checkbox
								v-model="selectedLlpID"
								:value="item.LlpID"
								@click="addPallet(item)"
								hide-details
							>
							</v-checkbox>
						</template> -->
					</v-data-table>
				</v-col>
				<v-col cols="2" align="end">
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								color="info"
								fab
								x-large
								dark
								@click="createPallet()">
								<span class="white--text text-h4">{{totalJabas}}</span>
							</v-btn>
						</template>
						<span>Unir Pallets</span>
					</v-tooltip>
					
				</v-col>
			</v-row>
		<!-- </v-container> -->
	</v-card>
</template>

<script>
	import _sMatureBalancePalletService from "@/services/FrozenProduction/MatureBalancePalletService";
	import _sParameter from "@/services/General/ParameterService.js";

	export default {
		props: {
			recharge: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				Destiny: 1,
				selectedLlpID: [],
				MaxJabasArm : 0,
				TypePallet: 0,
				selectedPallets: [],
				totalJabas: 0,
				pallets: [],
				configScann: {},
				headers: [
					{text: "Acción", value: "delete"},
					// {text: "Unir", value: "aggregate"},
					{text: "ID", value: "LlpID"},
					{text: "N° Jabas", value: "LlpQuantityJaba"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
				],
			}
		},
		
		watch: {
			recharge() {
				this.Initialize();
			}
		},

		methods: {
			Initialize() 
			{
				// _sMatureBalancePalletService
				// .listpalletpartition(this.Destiny, this.$fun.getUserID())
				// .then(resp => {
				// 	if(resp.status == 200){
				// 		this.pallets = resp.data;
						
				// 	}
				// })
			},

			deletePallet(item) {
				let LlpIDDelete = item.LlpID;
				this.pallets.forEach((elemento, index)=>{
					if(elemento.LlpID == LlpIDDelete){
						this.pallets.splice(index,1)
						this.addPallet()
					}
				})

				console.log('datos que se van a eliminar', this.pallets)


			},

			addPallet()
			{
				// this.selectedPallets = [];
				// this.totalJabas = 0;
				// this.pallets.forEach(element => {
				// 	if(this.selectedLlpID.includes(element.LlpID)){
				// 		this.selectedPallets.push(element);
				// 		this.totalJabas = this.totalJabas + element.LlpQuantityJaba;
				// 	}
				// });

				this.totalJabas = 0;
				this.pallets.forEach(element => {
					this.totalJabas = this.totalJabas + element.LlpQuantityJaba;
				})

				console.log('datos de los pallet',this.pallets)



				// console.log(this.selectedPallets);
			},

			closeViewJoinPalletTunnel()
			{
				this.$emit("closeViewJoinPalletTunnel");
			},

			createPallet()
			{	
				if(this.pallets.length == 0){
					this.$fun.alert("No hay pallets registrados", "info");
					return;
				}

				if(this.TypePallet == 0 || this.TypePallet == undefined){
					this.$fun.alert("Debe seleccionar el tipo de pallet");
					return;
				}

				if(this.pallets.length == 1){
					this.$fun.alert("Error, para la union de Pallet debe seleccionar mas de 1", "warning");
					return;
				}

				this.pallets.forEach((element)=>{
					this.selectedLlpID.push(element.LlpID);
				})

				this.$fun.alert("¿Seguro de unir pallets? sam", "question")
				.then(r => {
					if(r.value){

						//console.log("los pallet a unir samir", this.selectedLlpID)
						//return;
						//samir
						let objSend = {
							LlpIDString: this.selectedLlpID.join(', '),
							UsrCreateID: this.$fun.getUserID(),
							Destiny: this.Destiny,
							LlpIDRepresentative: this.getLlpIDRepresentative(),
							RcfIDRepresentative: this.getRcfIDRepresentative(),
							TypePallet : this.TypePallet, 
							PrsID: this.$fun.getUserInfo().PrsID
						}
						console.log('objSend ', objSend);


						_sMatureBalancePalletService
						.joinpallets(objSend, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Pallet creado correctamente", "success");
								this.Initialize();
								this.pallets = []
								this.$emit("closeViewJoinPalletTunnel");
							}
						})
					}
				})
			},

			getLlpIDRepresentative() {
				var max = 0;
				var LlpID = 0;
				this.pallets.forEach(element => {
					if (max <= element.LlpQuantityJaba) {
						max = element.LlpQuantityJaba;
						LlpID = element.LlpID;
					}
				});
				return LlpID;
			},

			getRcfIDRepresentative() {
				var max = 0;
				var RcfID = 0;
				this.pallets.forEach(element => {
					if (max <= element.LlpQuantityJaba) {
						max = element.LlpQuantityJaba;
						RcfID = element.RcfIDRepresentative;
					}
				});
				return RcfID;
			},

			onValue(item) {
				let LlpIDSearch = item;
				
				_sMatureBalancePalletService.listpalletpartition(item, this.$fun.getUserID())
				.then((response)=>{
					if(response.status == 200) {
						//samir
						console.log("response.data[0]", response.data[0]);

						if(response.data[0] == undefined){
							this.$fun.alert("No se encontro Pallet", "warning");
							return;
						}

						if(this.pallets.length > 0){
							let findedTc = this.pallets.find(element => element.TypeCultive == response.data[0].TypeCultive);				
							let findedId = this.pallets.find(element => element.LlpID == response.data[0].LlpID);				
							let findedFtsId = this.pallets.find(element => element.FtsID == response.data[0].FtsID);				
							if(findedTc == undefined){							
								this.$fun.alert("El Tipo de cultivo de los Pallets es diferente", "warning");
								return;
							}
							if(findedId != undefined){								
								this.$fun.alert("Este Pallet ya esta Agregado", "warning");
								return;
							}
							if(findedFtsId == undefined){								
								this.$fun.alert("El Estado de la Fruta no coincide con un Pallet ya Ingresado", "warning");
								return;
							}
						}
						//
						if(this.totalJabas > this.MaxJabasArm || (this.totalJabas+response.data[0].LlpQuantityJaba) > this.MaxJabasArm){
							this.$fun.alert("No puedes unir este pallet porque supera la cantidad de jabas")
							return;
						} else {
							console.log('datos escaneados con el qr',response.data);
							this.pallets.push(...response.data)
							response.data = []
						}
					}
					this.addPallet()
				})

				
				

			},
			
		},

		created () {
			
			let a = {
				PrmName: "FrozenCalibradoArmadoParihJabas"
			};
			_sParameter.search(a, this.$fun.getUserID()).then((r) => {
				this.MaxJabasArm = r.data.PrmValue
				console.log(this.MaxJabasArm)
			});
			

			console.log('actualziar siempre');
			this.Initialize();
		},
	}
</script>
