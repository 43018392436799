<template>
	<div>
		<v-container style="display: contents;">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row justify="center" style="margin:auto">
							<v-col
								align="center"
								cols="12"
								lg="10"
								md="10">
								<s-scanner-qr
									:config="configScann"
									return-object
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"
								></s-scanner-qr>
								<!-- <span>
									{{info}}
								</span> -->
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="12" align="center">
								<v-tooltip bottom="">
									<template v-slot:activator="{ on }">
										<v-btn
											v-on="on"
											color="success"
											fab
											@click="viewJoinPalletTunnel()"
										>
											<i class="fas fa-columns"></i>
										</v-btn>
									</template>
									<span>Unir Pallets</span>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row class="ml-5 mr-5" justify="center" style="margin:auto">
							<v-col cols="12">
								<s-toolbar
									dark
									:color="'#BAB6B5'"
									class="mr-4 ml-2"
									style="display: flex"
									print
									@print="printQR()"
								>

								</s-toolbar>
								<v-data-table
									:headers="headers"
									:items="palletspu"
									dense
									item-key="LlpID"
									v-model="selected"
									@click:row="rowClickSend"
								>

								<template v-slot:item.LlpBalancePartition="row">
									<v-chip small :color="(row.item.LlpBalancePartition==1) ? 'success' : 'warning'">
										{{ (row.item.LlpBalancePartition==1) ? 'Particion' : 'Union'  }}
									</v-chip>
								</template>

								</v-data-table>
							</v-col>
						</v-row>

					</v-card>
					
				</div>
			</v-row>

		</v-container>

		<v-dialog
			v-model="dialogJoinPalletTunnel"
			persistent
			width="1000"
		>
			<join-pallet-tunnel
				@closeViewJoinPalletTunnel="closeViewJoinPalletTunnel()"
				:recharge="recharge"
			></join-pallet-tunnel>
		</v-dialog>

	<!-- 	<v-dialog
			v-model="dialogJoinPalletChamber"
			persistent
			width="1000"
		>
			<join-pallet-chamber
				@closeViewJoinPalletChamber="closeViewJoinPalletChamber()"
				:recharge="recharge"
			></join-pallet-chamber>
		</v-dialog> -->

		<v-dialog v-model="dialogBalance" persistent v-if="dialogBalance" width="700">
			<v-card>
				<pallet-balance
					@CloseBalance="CloseBalance"
					:pallet="itemsampling"
				></pallet-balance>
			</v-card>
		</v-dialog>




	<v-card>
		<v-row justify="center" cols="12" style="display: none">
			<v-col cols="12">
				
				<qr-code
					:text="
						selectedPrint.LlpID +
							',' +
							selectedPrint.LlpQuantityJaba +
							',' +
							selectedPrint.TypeFrozenCategoryName +
							',' +
							selectedPrint.TypeSelectionFrozenName +
							',' +
							selectedPrint.TypeCropName +
							',' +
							selectedPrint.TypeCultiveName +
							',' +
							selectedPrint.VrtName +
							',' +
							selectedPrint.LlpWeight
					"
					error-level="H"
					:size="500"
					hidden
				>
				</qr-code>
				<br />
			</v-col>
		</v-row>
	</v-card>


	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService";
	import _sMaturationTunnelPalletReEntryService from "@/services/FrozenProduction/MaturationTunnelPalletReEntryService";
	import _sLotProductionSelecionPalletSendService from "@/services/FrozenProduction/FrzLotProductionSelectionPalletSendService.js"
	import SSelectFruitState from '../../../components/FrozenProduction/FruitState/SSelectFruitState.vue';
	import PalletBalance from "@/views/FrozenProduction/WashDisinfect/PalletBalance";
	import JoinPalletTunnel from "./JoinPalletTunnel.vue";
	import JoinPalletChamber from "./JoinPalletChamber.vue";
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			SSelectFruitState,
			PalletBalance,
			JoinPalletTunnel,
			JoinPalletChamber,
			qrCode
		},

		data() {
			return {
				selectedPrint: {},
				selected: [],
				palletspu: [],
				dialogJoinPalletChamber: false,
				recharge: false,
				dialogJoinPalletTunnel: false,
				itemsampling: {},
				dialogBalance: false,
				dialogWeight: false,
				pallets: [],
				pallet: {},
				palletNew: {},
				palletPartial: {},
				filter : {
					FtmID: 0,
					TypeMaturation: 0,
					MtpStatus: 1
				},
				configScann: {},
				headers: [
					{ text: "Id", value: "LlpID" },
					/* { text: "Lote Producción", value: "PrfYuliano" },
					{ text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide", width: 300 }, */
					{ text: "Tipo Pallet", value : "LlpBalancePartition"},
					{ text: "Tipo Selección", value: "TypeSelectionFrozenName" },
					{ text: "Categoría", value: "TypeFrozenCategoryName" },
					{ text: "Peso Bruto", value: "LlpWeightOriginalGross"},
					{ text:"Peso Neto", value:"LlpWeightOriginalNet"},
					{ text: "Cant. Jabas", value: "LlpQuantityJaba" },
					{ text: "Tipo Cultivo", value: "TypeCropName" },
					{ text: "Cultivo", value: "TypeCultiveName" },
					{ text: "Estado de Fruta", value: "FtsName" },
					{ text: "Variedad", value: "VrtName" },
				],
				LlpID: 0,
				QuantityJabaSeparate: 0,
				info: "",
				TypeCultive: 0,
				dialog: false,
				totalJabas: 0,
				itemsDetail : [],
			}
		},

		methods: {

			rowClickSend: function(item, row) {
				this.selectedPrint = item;
				this.selected = [item];
				console.log('dddddddddddd ', this.selected);
				if(this.selected.length > 0) {
					console.log('datos que envio', this.selected[0].LlpID)
					_sLotProductionSelecionPalletSendService
					.GetDetailsAllQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
						if (resp.status == 200) {
							this.itemsDetail = resp.data;
							console.log('datos que me llegan',this.itemsDetail);
						}
					});
				}

			},

			printQR() {

				if(this.itemsDetail.length > 0) {

					var imgAll = document.querySelector("img");
					console.log('imgAll ', imgAll);
					var Pagelink = "about:blank";
					var pwa = window.open(Pagelink, "_new");
					pwa.document.open();
					pwa.document.write(
						"<html><head><scri" +
							"pt>function step1(){\n" +
							"setTimeout('step2()', 10);}\n" +
							"function step2(){window.print();window.close()}\n" +
							"</scri" +
							"pt><style  media='print'>  @media print  { @page { margin-left: 3cm; {} </style></head><body onload='step1()'> \n" +
							"<div class='col col-12 justify-center'>",
					);
	
					
					/* imgAll.forEach(element => { */
						pwa.document.write(
							"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
								imgAll.currentSrc + 
								"' /></div>",
						);
	
						pwa.document.write("<div ><table border='0'>");
						pwa.document.write(
									"<tr><td colspan='7'>Datos Generales: " + 
									"<tr>" +
									"<td>" + "<b>"+this.selected[0].LlpID + "</b>"+
									"</td><td> ||" + "<b>"+this.selected[0].TypeCultiveName + "</b>"+
									"</td><td> || " + "<b>"+this.selected[0].VrtName + "</b>"+
									"</td><td> || " + "<b>"+this.selected[0].TypeCropName + "</b>"+
									"</td><td> || " + "<b>"+this.selected[0].TypeSelectionFrozenName + "</b>"+
									"</td><td> || " + "<b>"+this.selected[0].TypeFrozenCategoryName + "</b>"+
									"</td><td> || " + "<b>"+this.selected[0].LlpWeightOriginalNet + "</b>"+
									" </td></tr>",
						);
						pwa.document.write("</table></div>");
	
						this.itemsDetail.forEach(detail => {
								console.log("dedffsfsf", detail);
								pwa.document.write("<br><div ><table border='0'>");
	
								pwa.document.write(
									
									"<tr><td>Lote Produccion: </td><td>" + detail.RcfID +" || " +detail.PrfYuliano+ "</td></tr>" +
									"<tr><td>Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
								);
	
								pwa.document.write(
									"<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
									"<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>" +
									"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
								);
	
								pwa.document.write(
									"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>" +
									"<tr><td>Cod Parih. ant.:</td><td>"+ detail.LlpIDOLDName +"</td></tr>"
								);

								
								pwa.document.write("</table></div>");
							});
	
					/* }); */
	
				/* 	pwa.document.write("<b style='font-size: 80px; z-index: 2;transform: rotateZ(0deg);margin-top: 0px;margin-left: 0px;position: absolute;width: 133px;border-radius: 20px;'>" + this.selected[0].TypeSelectionFrozenName + "</b>"); */
	
					pwa.document.write("</div></body></html>");
					pwa.document.close();

				} else {

					this.$fun.alert("El pallet no es una particion ni una union", "warning");

				}

			},

			/* closeViewJoinPalletChamber()
			{
				
				this.dialogJoinPalletChamber = false;
				this.getListPalletPartJoin()
			}, */

			closeViewJoinPalletTunnel()
			{
				this.dialogJoinPalletTunnel = false;
				this.recharge = false;
				this.getListPalletPartJoin()
			},

			/* viewJoinPalletChamber()
			{
				this.dialogJoinPalletChamber = true;
			}, */

			viewJoinPalletTunnel()
			{
				this.dialogJoinPalletTunnel = true;
				this.recharge = true;
			},

			CloseBalance() {
				this.dialogBalance = false;
				this.getListPalletPartJoin()
			},

			getListPalletPartJoin()
			{
				_sLotProductionSelecionPalletSendService
				.listpalletpartjoin(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.palletspu = resp.data;
						console.log('Datos Traidos david',this.palletspu)
					}
				})
			},

			onValue(item)
			{
				if(this.totalJabas == 48){
					this.$fun.alert("Parihuela llena", "warning");
					return;
				}

				if(item == "" || item == null){
					this.$fun.alert("Ingrese o escanee parihuela", "warning");
					return;
				}

				if(isNaN(item)){
					this.LlpID = item.split(",")[0];
				}else{
					this.LlpID = item;
				}

				_sMaturationTunnelPalletService.getMtp(this.LlpID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsampling = resp.data;
						this.pallet = resp.data;
						console.log('Pallet que me escanea el QR ', this.itemsampling);
						this.info = this.pallet.TypeSelectionFrozenName + " || " + this.pallet.TypeFrozenCategoryName + " || " + this.pallet.TypeCropName + " || " + this.pallet.TypeCultiveName + " || " + this.pallet.VrtName + " || " + this.pallet.FtsName + " || N° Jabas: " + this.pallet.LlpQuantityJaba;
						this.palletNew = this.pallet;
						this.TypeCultive = this.pallet.TypeCultive;
						this.dialog = true;
						this.dialogBalance = true;
					}
				})
				
			},

			addNew()
			{
				this.palletPartial.MprID = 0;
				this.palletPartial.MtpID = this.palletNew.MtpID;
				this.palletPartial.LlpID = this.palletNew.LlpID;
				this.palletPartial.SecStatus = 1;
				this.palletPartial.UsrCreateID = this.$fun.getUserID();
				this.palletPartial.UsrUpdateID = this.$fun.getUserID();
				
				_sMaturationTunnelPalletReEntryService
				.save(this.palletPartial, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Agregado Correctamente", "success");
						this.dialog = false;
						this.Initialize();
					}
				})
			},


		},

		created () {
			this.getListPalletPartJoin();
		},

	}
</script>


			/* Initialize() {
				_sMaturationTunnelPalletService
				.reentrylist(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.pallets = resp.data;
						this.pallets.forEach(element => {
							this.totalJabas += element.MptQuantityJaba
						})
					}
				})
			}, */

			/* deletePallet(item)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						item.SecStatus = 0;
						item.UsrUpdateID = this.$fun.getUserID();
						this.totalJabas = this.totalJabas - item.MptQuantityJaba;
						_sMaturationTunnelPalletReEntryService.update(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Jabas eliminadas correctamente", "success");
								this.dialog = false;
								this.Initialize();
							}
						})
					}
				})
				
			},
 */
			/* add()
			{
				let ReEntry = {
					MtpID : this.palletNew.MtpID,
					MptQuantityJaba : this.palletPartial.MptQuantityJaba,
					MptWeight : this.palletPartial.MptWeight,
					MptWeightOld : this.palletPartial.MptWeightOld,
					FtsID : this.palletPartial.FtsID,
					UsrCreateID : this.$fun.getUserID(),
				}
				console.log('senddd', ReEntry);
				_sMaturationTunnelPalletReEntryService.insert(ReEntry, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Jabas apiladas correctamente", "success");
						this.dialog = false;
						this.Initialize();
					}
				})
			}, */

			/* createWeight()
			{
				this.dialogWeight = true;
				this.dialogBalance = true;
			}, */


			/* createPallet()
			{
				if(this.palletPartial.MptWeight == 0 )
				{
					this.$fun.alert("Ingrese el peso del pallet", "warning");
					return;
				}

				if(this.pallets.length > 0)
				{
					this.$fun.alert("¿Seguro de crear Pallet?", "question")
					.then(r => {
						if(r.value){
							let representativeLot = this.pallets[0];
							let totalJab = 0;
							let maxjaba = this.pallets[0].MptQuantityJaba;
							this.pallets.forEach(element => {
								if(element.MptQuantityJaba > maxjaba){
									representativeLot = element;
									representativeLot.MptWeight = this.palletPartial.MptWeight;
								}
							});
							this.pallets.forEach(element => {
								totalJab = totalJab + element.MptQuantityJaba;
							});
							representativeLot.MptWeight = this.palletPartial.MptWeight;
							representativeLot.MptQuantityJaba = totalJab;
							_sMaturationTunnelPalletReEntryService
							.entry(representativeLot, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200){
									this.$fun.alert("Parihuela creada correctamente", "success");
									this.Initialize();
									this.dialogWeight = false;
								}
							})
							
							console.log('next ', representativeLot);
						}
					})

					
				}
			} */
		<!-- <v-dialog
			v-model="dialog"
			v-if="dialog"
			width="600"
			persistent
		>
			<v-card>
				<s-toolbar
					label="Pallets"
					add
					@add="add"
					color="#999999"
					dark
					close
					@close="dialog = false"
				>	
				</s-toolbar>
				<v-container>
					<v-row class="mt-0 mb-0">
						<v-col cols="4" class="s-col-form">
							<s-text
								v-model="palletPartial.MptQuantityJaba"
								label="N° Jabas"
							>
							</s-text>
						</v-col>
						<v-col cols="4" class="s-col-form">
							<s-text
								v-model="palletPartial.MptWeightOld"
								decimal
								label="Peso Restante"
							>
							</s-text>
						</v-col>
						<v-col cols="4" class="s-col-form">
							<s-select-fruit-state
								v-model="palletPartial.FtsID"
								full
								:TypeCultive="TypeCultive"
								label="Estado Fruta"
							></s-select-fruit-state>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogWeight"
			v-if="dialogWeight"
			width="400"
		>
			<v-card>
				<s-toolbar
					label="Registrar Peso de Pallet"
					dark
					:color="'#BAB6B5'"
					close
					@close="dialogWeight = false"
					save
					@save="createPallet()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12">
							<s-text
								v-model="palletPartial.MptWeight"
								decimal
								label="Peso Nuevo"
							>
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog> -->